// docs for router https://github.com/thepassle/app-tools/blob/master/router/README.md

import { html } from 'lit';

if (!(globalThis as any).URLPattern) {
  await import("urlpattern-polyfill");
}

import { Router } from '@thepassle/app-tools/router.js';
import { lazy } from '@thepassle/app-tools/router/plugins/lazy.js';

// @ts-ignore
import { title } from '@thepassle/app-tools/router/plugins/title.js';
import { appName } from '@thepassle/app-tools/router/plugins/appName.js';
import { scrollToTop } from '@thepassle/app-tools/router/plugins/scrollToTop.js';

import './pages/app-home.js';


const baseURL: string = (import.meta as any).env.BASE_URL;

export const router = new Router({
  plugins: [
    appName('ScadaGo ::'),
    scrollToTop
  ],
  fallback: '/404',
  routes: [
    {
      path: resolveRouterPath(''),
      title: 'Inicio',
      render: () => html`<app-home></app-home>`
    },
    {
      path: resolveRouterPath('nosotros'),
      title: 'Nosotros',
      plugins: [
        lazy(() => import('./pages/app-about/app-about.js')),
      ],
      render: () => html`<app-about></app-about>`
    },
    {
      path: resolveRouterPath('contactanos'),
      title: 'Contactanos',
      plugins: [
        lazy(() => import('./pages/app-contactanos/app-contactanos.js')),
      ],
      render: () => html`<app-contactanos></app-contactanos>`
    },
    {
      path: resolveRouterPath('soluciones/mobile'),
      title: 'Aplicación móvil',
      plugins: [
        lazy(() => import('./pages/app-mobile/app-mobile.js')),
      ],
      render: () => html`<app-mobile></app-mobile>`
    },
    {
      path: resolveRouterPath('soluciones/pwa'),
      title: 'Acceso web',
      plugins: [
        lazy(() => import('./pages/app-pwa/app-pwa.js')),
      ],
      render: () => html`<app-pwa></app-pwa>`
    },
    {
      path: '/404',
      title: 'Not found',
      render: () => html`<404-page></404-page>`
    },
    {
      path: '/download/:asset',
      title: ({params}) => `Download ${params?.asset}`,
      plugins: [
        lazy(() => import('./pages/app-download/app-download.js'))
      ],
      render: ({params}) => html`<app-download asset="${params.asset}"></app-download>`
    },
  ]
});

  // This function will resolve a path with whatever Base URL was passed to the vite build process.
  // Use of this function throughout the starter is not required, but highly recommended, especially if you plan to use GitHub Pages to deploy.
  // If no arg is passed to this function, it will return the base URL.

  export function resolveRouterPath(unresolvedPath?: string) {
    var resolvedPath = baseURL;
    if(unresolvedPath) {
      resolvedPath = resolvedPath + unresolvedPath;
    }

    return resolvedPath;
  }


  // Check current path against one given
  // QUE HACER con el primer caracter '/'?
  // 1 -> se quita de currentPath           [ ]
  // 2 -> se agrega a givenPath al comienzo [x]
  export function isCurrentRouterPath(givenPath?: string) {
    let currentPath = window.location.pathname;
    // console.log("current:" + currentPath + "| givenPath:" + givenPath);
    if (currentPath==="/" + givenPath) {
      // console.log("Coinciden!");
      return true;
    } else {
      return false;
    }
  }
