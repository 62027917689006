import { LitElement, css, html } from 'lit';
import { property, customElement, state } from 'lit/decorators.js';
// import { resolveRouterPath } from '../router';
// import { isCurrentRouterPath } from '../router';

import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button.js';
import '@shoelace-style/shoelace/dist/components/menu/menu.js';
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js';
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js';
import '@shoelace-style/shoelace/dist/components/drawer/drawer.js';
import '@shoelace-style/shoelace/dist/components/animation/animation.js';

import { registerIconLibrary } from '@shoelace-style/shoelace/dist/utilities/icon-library.js';

registerIconLibrary('default', {
  resolver: name => `https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/icons/${name}.svg`
});

import { styles } from '../../public/assets/themes/dark';

@customElement('app-footer')
export class AppFooter extends LitElement {
  @property({ type: String }) title = 'ScadaGo';

  @property({ type: Boolean}) enableBack: boolean = false;

  @property({ type: Boolean}) quieroProbarlo: boolean = false;

  @state() isDrawerOpen: boolean = false;

  static styles = [styles, css`

    .lg-showme {
      display: none;
    }
    .lg-hideme {
      display: block;
    }
    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
      .lg-showme {
        display: block !important;
      }
      .lg-hideme {
        display: none;
      }
    }

    sl-button.chatOnWhatsapp-button  {
      position: fixed; bottom: 100px; right: 90px;
    }
    sl-button.chatOnWhatsapp-button::part(base)  {
      border-radius: var(--sl-border-radius-large);
      background-color: #25D366;
      border-color: #25D366;
      color: #fff;
      font-size: var(--sl-font-size-x-large);
    }

    sl-button.chatOnWhatsapp-button::part(label)  {
      color: #fff;
      font-size: var(--sl-font-size-large);
    }

  `];

  render() {
    return html`
    <footer>
      ${this.quieroProbarlo ? html`<app-quiero_probarlo></app-quiero_probarlo>` : null}

      <app-section variant="single-content" color="estiloFooter">
        <div class="panel">
          <p style="text-align: center">© 2024 ScadaGO. All rights reserved.</p>
        </div>
      </app-section>


      <sl-animation name="bounceInRight" easing="ease-in-out" iterations="1" duration="1500" play>
        <sl-button
          class="chatOnWhatsapp-button"
          href="https://api.whatsapp.com/send/?phone=543414540500&amp;text=Quiero+probar+ScadaGo!%0D%0AMi+nombre+es...&amp;type=phone_number&amp;app_absent=0"
          target="_blank"
          size="large">
          <sl-icon slot="prefix" name="whatsapp"></sl-icon>
          Chatea en WhatsApp
        </sl-button>
      </sl-animation>
    </footer>


    `;
  }
}


