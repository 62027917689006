import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { resolveRouterPath } from '../router';

import '@shoelace-style/shoelace/dist/components/button/button.js';

import { styles } from '../styles/shared-styles';

@customElement('app-home')
export class AppHome extends LitElement {

  // For more information on using properties and state in lit
  // check out this link https://lit.dev/docs/components/properties/
  @property() message = 'Welcome!';

  static styles = [
    styles,
    css`

  `];

  async firstUpdated() {
    // this method is a lifecycle even in lit
    // for more info check out the lit docs https://lit.dev/docs/components/lifecycle/
    console.log('This is your home page');
  }

  share() {
    if ((navigator as any).share) {
      (navigator as any).share({
        title: 'PWABuilder pwa-starter',
        text: 'Check out the PWABuilder pwa-starter!',
        url: 'https://github.com/pwa-builder/pwa-starter',
      });
    }
  }

  render() {
    return html`
      <app-header></app-header>

      <app-section variant="hero"
                   bgImage="assets/images/encabezado-inicio.webp"
                   bgImageVerticalPosition="bottom">S<span style="font-size: 51pt;">cada</span>G<span style="font-size: 51pt;">O</span></app-section>

      <app-section variant="single-content" color="estilo1">
        <div class="panel">
          <h2>Soluciones</h2>
          <p>Nuestras soluciones permiten la supervisión en tiempo real de todos los aspectos críticos de tus operaciones. Desde la producción hasta la distribución, tendrás acceso a datos precisos para tomar decisiones informadas.
          ScadaGo ofrece dos opciones excepcionales para satisfacer sus necesidades. Ya sea que prefieran la experiencia nativa y las funciones específicas del dispositivo de nuestra aplicación móvil o la flexibilidad y la accesibilidad de nuestra PWA, estamos comprometidos a proporcionar una experiencia de usuario superior en ambas plataformas. Cada una tiene sus propias ventajas que se pueden aprovechar al máximo.</p>
        </div>
      </app-section>

      <app-section variant="single-content" color="estilo3">
        <div class="panel">
          <h2 style="text-align: center">APPLICACIÓN MÓVIL <span style="font-size: 28pt;">MOBILE APP</span></h2>
          <div style="width: 200px; margin-left: auto; margin-right: auto;">
            <a href='https://play.google.com/store/apps/details?id=com.scadago&hl=es_AR&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank">
            <img style="width: 200px;" alt='Disponible en Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/es-419_badge_web_generic.png'/>
            </a>
          </div>
        </div>
      </app-section>

      <app-section variant="double-content" color="estilo1">
        <div class="panel">
          <img src="assets/images/seccion-rendimiento.webp" alt="Aplcación Móbil (mobile App)" class="image-box">
        </div>
        <div class="panel">
          <h3>Rendimiento y Experiencia Nativa</h3>
          <p>Nuestra aplicación móvil ofrece un rendimiento excepcional y una experiencia nativa. Con características específicas del dispositivo y una interfaz diseñada para plataformas móviles, proporcionamos una experiencia fluida y envolvente para aquellos que buscan la mejor calidad en dispositivos móviles.</p>
          <div style="max-width: 320px; margin-left: auto; margin-right: auto;"><a href="${resolveRouterPath('soluciones/mobile')}" style="display: block; background-color: #D8334A; color: #fff; padding: 1em 2em; border: none; font-family: Roboto, Arial; font-size: 16.0pt; font-weight: bold; border-radius: 2em; text-decoration: none; text-align: center;">+ Aplicación móvil
          </a></div>
        </div>
      </app-section>

      <app-section variant="double-content" color="estilo2">
        <div class="panel">
          <h3>ACCESO A FUNCIONES DEL DISPOSITIVO</h3>
          <p>La aplicación móvil aprovecha al máximo las funciones del dispositivo, como notificaciones push, acceso a la cámara y otras características específicas. Esto permite una experiencia rica y personalizada que se integra perfectamente con las capacidades de su teléfono o tableta</p>
        </div>
        <div class="panel">
          <h3>DISPONIBILIDAD EN TIENDAS DE APPS</h3>
          <p>Nuestra aplicación móvil está disponible para su descarga en las principales tiendas de aplicaciones, lo que facilita a los usuarios encontrar, instalar y actualizar la aplicación de manera conveniente</p>
        </div>
      </app-section>

      <app-section variant="single-content" color="estilo3">
        <div class="panel">
          <h2 style="text-align: center">ACCESO WEB <span style="font-size: 28pt;">PWA</span></h2>
          <div style="max-width: 320px; margin-left: auto; margin-right: auto;">
            <a href="https://scadago.com/dashboard" target="_blank" style="display: block; background-color: white; color: #000; padding: 1em 2em; border: none; font-family: Roboto, Arial; font-size: 16.0pt; font-weight: bold; border-radius: 2em; text-decoration: none; text-align: center;">Ingresar
            </a>
          </div>
        </div>
      </app-section>

      <app-section variant="double-content" color="estilo1">
        <div class="panel">
          <h3>ACCESO DESDE CUALQUIER NAVEGADOR</h3>
          <p>La PWA de ScadaGo ofrece la flexibilidad de acceso desde cualquier navegador. Esto significa que los usuarios pueden disfrutar de todas las funciones sin necesidad de descargar o instalar nada adicional, simplemente a través de su navegador web favorito</p>
          <div style="max-width: 320px; margin-left: auto; margin-right: auto;">
            <a href="${resolveRouterPath('soluciones/pwa')}" style="display: block; background-color: #D8334A; color: #fff; padding: 1em 2em; border: none; font-family: Roboto, Arial; font-size: 16.0pt; font-weight: bold; border-radius: 2em; text-decoration: none; text-align: center;">+ Acceso web</a>
          </div>
        </div>
        <div class="panel">
          <img src="assets/images/seccion-pwa.webp" alt="Aplcación Móbil (mobile App)"  class="image-box">
        </div>
      </app-section>

      <app-section variant="double-content" color="estilo2">
        <div class="panel">
          <h3>EXPERIENCIA CONTINUA SIN CONEXIÓN</h3>
          <p>Una característica destacada de nuestra PWA es la capacidad de funcionar incluso sin conexión a Internet. Los usuarios pueden acceder a ciertas funciones y datos, lo que mejora la accesibilidad y la experiencia del usuario, especialmente en condiciones de conectividad intermitente</p>
        </div>
        <div class="panel">
          <h3>ACTUALIZACIONES INSTANTÁNEAS</h3>
          <p>Las actualizaciones de la PWA se realizan de manera instantánea sin necesidad de pasar por las tiendas de aplicaciones. Esto significa que los usuarios siempre tienen acceso a las últimas mejoras y características sin demoras ni intermediarios</p>
        </div>
      </app-section>

      <app-footer ?quieroProbarlo="${true}"></app-footer>

    `;
  }
}
