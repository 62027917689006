import { LitElement, css, html } from 'lit';
import { property, customElement, state } from 'lit/decorators.js';
import { resolveRouterPath } from '../router';
import { isCurrentRouterPath } from '../router';

import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/menu/menu.js';
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js';
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js';
import '@shoelace-style/shoelace/dist/components/drawer/drawer.js';

import { registerIconLibrary } from '@shoelace-style/shoelace/dist/utilities/icon-library.js';

registerIconLibrary('default', {
  resolver: name => `https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/icons/${name}.svg`
});

import { styles } from '../../public/assets/themes/dark';

@customElement('app-header')
export class AppHeader extends LitElement {
  @property({ type: String }) title = 'ScadaGo';

  @property({ type: Boolean}) enableBack: boolean = false;

  @state() isDrawerOpen: boolean = false;

  static styles = [styles, css`
    header {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1030;
      background: #000;
      width: 100%;
      left: env(titlebar-area-x, 0);
      top: env(titlebar-area-y, 0);
      height: env(titlebar-area-height, 72px);
      width: env(titlebar-area-width, 100%);
    }
    header nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      padding: .75rem 2rem;
      max-width: 1140px;
      margin-left: auto;
      margin-right: auto;
    }

    header nav .brand-title {
      font-size: 1.777rem;
      font-weight: 400;
      /*font-family: 'staatliches', serif-sans;*/
    }

    header nav a {
      margin-left: 10px;
    }

    header nav .nabar-brand {
      display: flex;
      justify-content: start;
      align-items: center;
      font-family: var(--menu-font-family);
    }
    header nav .nabar-brand .brand-logo {
      margin-right: 1rem;
    }

    sl-button::part(base), sl-menu-item::part(base) {
      font-family:  var(--menu-font-family);
      font-size: 1.333rem;
      color: #fff;
    }
    sl-button.btn-login::part(base) { color: #000; }

    .lg-showme {
      display: none;
    }
    .lg-hideme {
      display: block;
    }
    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
      .lg-showme {
        display: block !important;
      }
      .lg-hideme {
        display: none;
      }
    }

  `];

  render() {
    return html`
      <header class="sl-theme-dark">
        <nav>

          <sl-drawer label="" placement="start" class="drawer-placement-start"
                     ?open="${this.isDrawerOpen}"
                     no-header="${true}"
                     @sl-after-hide="${()=>{this.isDrawerOpen=false}}">
            <sl-menu style="border: none">
              <sl-menu-item>
                <sl-button variant="${isCurrentRouterPath('') ? `danger`:`text`}"
                           href="${resolveRouterPath('')}"
                           @click="${(e: Event) => {if (isCurrentRouterPath('')) { e.preventDefault();} }}">Inicio</sl-button>
              </sl-menu-item>
              <sl-menu-item>
                <sl-dropdown>
                  <sl-button variant="${isCurrentRouterPath('soluciones/mobile') || isCurrentRouterPath('soluciones/pwa') ? `danger`:`text`}"
                            slot="trigger" caret>Soluciones</sl-button>
                  <sl-menu>
                    <sl-menu-item>
                      <sl-button variant="${isCurrentRouterPath('soluciones/mobile') ? `danger`:`text`}"
                                href="${resolveRouterPath('soluciones/mobile')}"
                                @click="${(e: Event) => {if (isCurrentRouterPath('soluciones/mobile')) { e.preventDefault();} }}">Aplicación móvil</sl-button>
                    </sl-menu-item>
                    <sl-menu-item>
                      <sl-button variant="${isCurrentRouterPath('soluciones/pwa') ? `danger`:`text`}"
                                href="${resolveRouterPath('soluciones/pwa')}"
                                @click="${(e: Event) => {if (isCurrentRouterPath('soluciones/pwa')) { e.preventDefault();} }}">acceso web</sl-button>
                    </sl-menu-item>
                  </sl-menu>
                </sl-dropdown>
              </sl-menu-item>

              <sl-menu-item>
                <sl-button variant="${isCurrentRouterPath('nosotros') ? `danger`:`text`}"
                           href="${resolveRouterPath('nosotros')}"
                           @click="${(e: Event) => {if (isCurrentRouterPath('nosotros')) { e.preventDefault();} }}">Nosotros</sl-button>

              </sl-menu-item>
              <sl-menu-item>
                <sl-button variant="${isCurrentRouterPath('contactanos') ? `danger`:`text`}"
                           href="${resolveRouterPath('contactanos')}"
                           @click="${(e: Event) => {if (isCurrentRouterPath('contactanos')) { e.preventDefault();} }}">Contactanos</sl-button>
              </sl-menu-item>
              <sl-menu-item>
                <sl-button variant="primary"
                           href="dashboard"
                           target="_blank"
                           class="btn-login">
                  <sl-icon slot="prefix" name="person-circle" style="font-size: 24px"></sl-icon>Ingresar</sl-button>
              </sl-menu-item>

            </sl-menu>
          </sl-drawer>

          <div class="nabar-brand">
            <sl-button class="lg-hideme" aria-label="Abrir el menú lateral" id="Menú lateral" title="Menú lateral" style="margin-right: 2rem" @click="${()=>{this.isDrawerOpen=true}}">
              <sl-icon name="list" aria-hidden="true"></sl-icon>
            </sl-button>
            <img src="/assets/icons/72x72.png" width="48" height="48" class="brand-logo" alt="ScadaGo Logo">
            <span class="brand-title">S<span style="font-size: 16pt;">cada</span>G<span style="font-size: 16pt;">o</span></span>
          </div>
          <div class="lg-showme">
            <sl-button-group label="Main nav menu" class="navbar-menu">
              <sl-button variant="${isCurrentRouterPath('') ? `danger`:`text`}"
                        href="${resolveRouterPath('')}"
                        @click="${(e: Event) => {if (isCurrentRouterPath('')) { e.preventDefault();} }}">Inicio</sl-button>
              <sl-dropdown>
                <sl-button variant="${isCurrentRouterPath('soluciones/mobile') || isCurrentRouterPath('soluciones/pwa') ? `danger`:`text`}"
                          slot="trigger" caret>Soluciones</sl-button>
                <sl-menu>
                  <sl-menu-item>
                    <sl-button variant="${isCurrentRouterPath('soluciones/mobile') ? `danger`:`text`}"
                              href="${resolveRouterPath('soluciones/mobile')}"
                              @click="${(e: Event) => {if (isCurrentRouterPath('soluciones/mobile')) { e.preventDefault();} }}">Aplicación móvil</sl-button>
                  </sl-menu-item>
                  <sl-menu-item>
                    <sl-button variant="${isCurrentRouterPath('soluciones/pwa') ? `danger`:`text`}"
                              href="${resolveRouterPath('soluciones/pwa')}"
                              @click="${(e: Event) => {if (isCurrentRouterPath('soluciones/pwa')) { e.preventDefault();} }}">acceso web</sl-button>
                  </sl-menu-item>
                </sl-menu>
              </sl-dropdown>
              <sl-button variant="${isCurrentRouterPath('nosotros') ? `danger`:`text`}"
                        href="${resolveRouterPath('nosotros')}"
                        @click="${(e: Event) => {if (isCurrentRouterPath('nosotros')) { e.preventDefault();} }}">Nosotros</sl-button>
              <sl-button variant="${isCurrentRouterPath('contactanos') ? `danger`:`text`}"
                        href="${resolveRouterPath('contactanos')}"
                        @click="${(e: Event) => {if (isCurrentRouterPath('contactanos')) { e.preventDefault();} }}">Contactanos</sl-button>
              <sl-button variant="primary"
                        href="dashboard"
                        target="_blank"
                        class="btn-login"><sl-icon slot="prefix" name="person-circle" style="font-size: 24px"></sl-icon>Ingresar</sl-button>
            </sl-button-group>
          </div>
        </nav>
      </header>

    `;
  }
}


