import { CSSResultGroup, LitElement, css, html, unsafeCSS } from 'lit';
import { property, customElement } from 'lit/decorators.js';

import { styles } from '../styles/shared-styles';

@customElement('app-section')
export class AppSection extends LitElement {

  @property({ type: String }) variant = 'hero';
  @property({ type: String }) color   = 'estilo1';
  @property({ type: String }) bgImage = '';
  @property({ type: String }) bgImageVerticalPosition = 'center';



  // @property({ type: Boolean}) enableBack: boolean = false;
  static myBgImage: number | CSSResultGroup;
  myBgImage = css`${unsafeCSS(this.bgImage)}`;

  /*

    @media (min-width: 576px) {

    }

    @media (min-width: 768px) {
      section {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }


    @media (min-width: 992px) {
      section {
        padding-left: 3rem;
        padding-right: 3rem;
      }
    }


    @media (min-width: 1200px) {

    }

    @media (min-width: 1400px) {

    }
*/

  static styles = [styles, css`
    section {
      padding-top: 100px;
      padding-bottom: 100px;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    section.hero {
      height: 340px;
      background-size: cover;
      background-attachment: scroll;
      background-repeat: no-repeat;
    }

    .wrapper {
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
    }

    .header {
      text-align: center;
      /*padding-top: 70px;*/
      color: #fff;
      margin-left: auto;
      margin-right: auto;
    }
    .header h1.hero-title {
      border-bottom-width: 12px;
      border-bottom-style: solid;
      border-bottom-color: rgba(216,51,74,1);
      display: block;
    }
    .header h1.hero-title {
      font-size: 5.61rem;
      font-weight: 400;
      margin: 0px;
      font-family: var(--headers-font-family) !important;
      background: rgba(0,0,0,.5);
      padding-bottom: 20px;
    }

    @media (prefers-color-scheme: light) {
      .estilo1 {
        background-color: #FFF;
        color: #333;
      }
      .estilo2 {
        background-color: #F3F3F3;
        color: #333;
      }
    }

    @media (prefers-color-scheme: dark) {
      .estilo1 {
        background-color: #181818;
        color: white;
      }
      .estilo2 {
        background-color: #3C3B3D;
        color: white;
      }
    }

    .estilo3 { background-color: #D8334A; color: #fff;}
    .estiloFooter { background-color: #333; color: #fff;}

    section.estiloFooter {
      padding-top: 18pt;
      padding-bottom: 18pt;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .single-content .wrapper {
      grid-template-columns: 1fr;
    }

    .double-content .wrapper {
      grid-template-columns: 1fr;
    }

    .triple-content .wrapper {
      grid-template-columns: 1fr;
    }

    .cuadruple-content .wrapper {
      grid-template-columns: 1fr;
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {
    }

    .panel {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    /* Medium devices (tablets, 768px and up) */
    @media(min-width: 768px) {
      .header {
        width: 66.6666666667%;
      }
      .double-content .wrapper,
      .triple-content .wrapper,
      .cuadruple-content .wrapper
       {
        grid-template-columns: 1fr 1fr;
      }
    }



    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
      section {
        padding-left: 3rem;
        padding-right: 3rem;
      }

      .triple-content .wrapper,
      .cuadruple-content .wrapper
       {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    /* X-Large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
      .cuadruple-content .wrapper
       {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }

    /* XX-Large devices (larger desktops, 1400px and up) */
    @media (min-width: 1400px) {

    }



  `];



  render() {
    return html`
      ${this.variant=='hero'?
      html`
      <section class="hero" style="background-image: url(${this.bgImage}); background-position: center ${this.bgImageVerticalPosition};">
        <div class="wrapper">
          <div class="header"><h1 class="hero-title"><slot></slot></h1></div>
        </div>
      </section>
      `:null}
      ${this.variant=='single-content'?
      html`
      <section class="single-content ${this.getEstilo(this.color)}">
        <div class="wrapper">
          <slot></slot>
        </div>
      </section>
      `:null}
      ${this.variant=='double-content'?
      html`
      <section class="double-content ${this.getEstilo(this.color)}">
        <div class="wrapper">
          <slot></slot>
        </div>
      </section>
      `:null}
      ${this.variant=='triple-content'?
      html`
      <section class="triple-content ${this.getEstilo(this.color)}">
        <div class="wrapper">
          <slot></slot>
        </div>
      </section>
      `:null}
      ${this.variant=='cuadruple-content'?
      html`
      <section class="cuadruple-content ${this.getEstilo(this.color)}">
        <div class="wrapper">
          <slot></slot>
        </div>
      </section>
      `:null}
    `;
  }

  getEstilo(estilo: String) {
    if (estilo==='estilo2') { return 'estilo2'; }
    else if (estilo==='estilo3') { return 'estilo3'; }
    else if (estilo==='estiloFooter') { return 'estiloFooter'; }
    else { return 'estilo1'; }
  }
}

