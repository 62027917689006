import { LitElement, css, html } from 'lit';
import { property, customElement, state } from 'lit/decorators.js';
import { resolveRouterPath } from '../router';
// import { isCurrentRouterPath } from '../router';

import { styles } from '../styles/shared-styles';

import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button.js';
import '@shoelace-style/shoelace/dist/components/menu/menu.js';
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js';
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js';
import '@shoelace-style/shoelace/dist/components/drawer/drawer.js';
import '@shoelace-style/shoelace/dist/components/animation/animation.js';

import { registerIconLibrary } from '@shoelace-style/shoelace/dist/utilities/icon-library.js';

registerIconLibrary('default', {
  resolver: name => `https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/icons/${name}.svg`
});

import { styles as darkTheme } from '../../public/assets/themes/dark';

@customElement('app-quiero_probarlo')
export class AppQuiero_probarlo extends LitElement {
  @property({ type: String }) title = 'ScadaGo';

  @property({ type: Boolean}) enableBack: boolean = false;

  @state() isDrawerOpen: boolean = false;

  static styles = [darkTheme, styles, css`

    .lg-showme {
      display: none;
    }
    .lg-hideme {
      display: block;
    }
    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
      .lg-showme {
        display: block !important;
      }
      .lg-hideme {
        display: none;
      }
    }
    .loginDemoInfo {
      text-decoration: none;
      list-style: none;
      width: 300px;
      text-align: left;
      margin-left: auto;
      margin-right: auto;
      background-color: #f3f3f3;
      border-radius: 1rem;
      padding: 1rem 3rem;
      color: rgb(0, 0, 0);
      font-size: 16pt;
    }

    sl-button::part(base) {
      color: var(--sl-color-neutral-1000);
      font-size: var(--sl-button-font-size-large) !important;
      text-decoration: underline;
      padding: 0 !important;
      min-height: 0;
      border: none;
      line-height: 18pt !important;
      vertical-align: bottom;
    }
    sl-button::part(label) {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  `];


render() {
  return html`
    <app-section variant="single-content" color="estilo3">
      <div class="panel" style="text-align: center">
        <h2 style="text-align: center">quiero probarlo</h2>
        <p>Queres probar el servidor DEMO en la
          <sl-button
            variant="text"
            size="large"
            href="https://play.google.com/store/apps/details?id=com.scadago&hl=es_AR&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            target="_blank">

            aplicación móvil
          </sl-button> o en el
          <sl-button variant="text" size="large" href="https://scadago.com/login" target="_blank">

            acceso web
          </sl-button>, solo tenes que ingresar con:</p>
        <ul class="loginDemoInfo">
          <li><strong>usuario</strong>: demo</li>
          <li><strong>password</strong>: demo</li>
        </ul>

        <p>Te gustaría ver ScadaGo en acción junto con tu SCADA, para probar la versión web y/o la aplicación móvil contactanos y completa el formulario con tus datos.</p>
        <div style="max-width: 320px; margin-left: auto; margin-right: auto;">
          <a href="${resolveRouterPath('contactanos')}" style="display: block; background-color: white; color: #000; padding: 1em 2em; border: none; font-family: Roboto, Arial; font-size: 16.0pt; font-weight: bold; border-radius: 2em; text-decoration: none; text-align: center;">Contactanos
          </a>
        </div>
      </div>
    </app-section>
    `
  }
}