import { css } from 'lit';

// these styles can be imported from any component
// for an example of how to use this, check /pages/about-about.ts
export const styles = css`
  @media(min-width: 1000px) {
    sl-card {
      max-width: 70vw;
    }
  }

  main {
    margin-top: 80px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--headers-font-family) !important;
    margin: 1rem 0;
  }
  h1 {font-size: 5.61rem !important; font-weight: 400 !important;}
  h2 {font-size: 4.209rem !important; font-weight: 400 !important;}
  h3 {font-size: 3.157rem !important; font-weight: 400 !important;}
  h4 {font-size: 2.369rem !important; font-weight: 400 !important;}
  h5 {font-size: 1.777rem !important; font-weight: 400 !important;}
  h6 {font-size: 1.333rem !important; font-weight: 400 !important;}


  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  body {
    margin: 40px;
    background-color: #fff;
    color: #444;
  }

  h1,
  p {
    margin: 0 0 1em 0;
  }

  .wrapper {
    max-width: 1280px;
    margin: 0 20px;
    display: grid;
    grid-gap: 10px;
  }


  /* no grid support? */

  .wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .wrapper {
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /*grid-auto-rows: minmax(150px, auto);*/
  }

  .panel {
    /* needed for the flex layout*/
    margin-left: 5px;
    margin-right: 5px;
    flex: 1 1 200px;
  }

  .header,
  .footer {
    margin-left: 5px;
    margin-right: 5px;
    flex: 0 1 100%;
    grid-column: 1 / -1;
  }

  .wrapper > * {

  }

  p {
    font-size: 12pt;
    line-height: 18pt;
  }

  /* We need to set the margin used on flex items to 0 as we have gaps in grid.  */

  @supports (display: grid) {
    .wrapper > * {
      margin: 0;
    }
  }

  ::slotted(.image-box) {
    max-width: 320px;
  }

  .image-box {
    max-width: 320px;
  }

  @media (min-width: 576px) {
    .image-box {
      max-width: 460px;
    }
  }


`;